



































































import { Component, Vue } from "vue-property-decorator";
import { authModule, resultsModule } from "@/store";

@Component({
  components: {},
})
export default class KTMenu extends Vue {
  mounted() {
    resultsModule.actions.getNewResultsCount();
  }

  hasActiveChildren(match: string) {
    return this.$route["path"].indexOf(match) !== -1;
  }

  logOut() {
    authModule.actions.logout().then(() => {
      this.$router.push({ name: "login" });
    });
  }

  get newResultsCount() {
    return resultsModule.state.newResultsCount;
  }
}
