









































import { Component, Vue, Watch } from "vue-property-decorator";
import KTHeaderMobile from "./header/HeaderMobile.vue";
import KTFooter from "./footer/Footer.vue";
import KTAside from "./aside/Aside.vue";
import KTStickyToolbar from "./extras/StickyToolbar.vue";
import KTScrollTop from "./extras/ScrollTop.vue";
import KTHeader from "@/view/layout/header/Header.vue";

import Loader from "@/view/content/Loader.vue";

import { authModule, layoutModule, profileModule } from "@/store";

@Component({
  components: {
    KTHeaderMobile,
    KTAside,
    KTFooter,
    KTStickyToolbar,
    KTScrollTop,
    Loader,
    KTHeader,
  },
})
export default class Layout extends Vue {
  // toolbarDisplay = false;

  get widthFluid() {
    return layoutModule.state.widthFluid;
  }

  get loaderEnabled() {
    return layoutModule.state.pageLoading;
  }

  get isAuthenticated() {
    return authModule.state.isAuthenticated;
  }

  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
      return;
    }
    profileModule.actions.loadPatients();
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    layoutModule.mutations.closeMobileMenu();
  }
}
