













import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Loader extends Vue {
  @Prop({ default: "" }) spinnerClass!: string;
}
