






































import { Component, Vue } from "vue-property-decorator";

import { authModule, layoutModule } from "@/store";

@Component({
  components: {},
})
export default class HeaderMobile extends Vue {
  headerClasses = [];

  toggleMenu() {
    layoutModule.mutations.toggleMobileMenu();
  }

  logOut() {
    authModule.actions.logout().then(() => {
      this.$router.push({ name: "login" });
    });
  }
}
