








































































import { Component, Vue } from "vue-property-decorator";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";

import { authModule, layoutModule } from "@/store";

@Component({
  components: {
    KTBrand,
    KTMenu,
  },
})
export default class KTAside extends Vue {
  get asideMenuClasses() {
    return layoutModule.state.asideMenuClasses;
  }

  menu = [
    {
      link: "/home",
      name: "Результаты",
      icon: require("@/assets/media/svg/icons/Layout/Layout-4-blocks.svg"),
    },
    {
      link: "/plots",
      name: "Графики",
      icon: require("@/assets/media/svg/icons/Shopping/Chart-line1.svg"),
    },
    {
      link: "/profile",
      name: "Настройки",
      icon: require("@/assets/media/svg/icons/General/User.svg"),
    },
  ];

  logOut() {
    authModule.actions.logout();
  }
}
