


















import { Component, Vue } from "vue-property-decorator";

import { layoutModule } from "@/store";

@Component({})
export default class KTFooter extends Vue {
  get widthFluid() {
    return layoutModule.state.widthFluid;
  }
}
