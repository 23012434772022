



















import { Component, Vue } from "vue-property-decorator";
import { authModule } from "@/store";

@Component({
  components: {},
})
export default class KTTopbar extends Vue {
  hasActiveChildren(match: string) {
    return this.$route["path"].indexOf(match) !== -1;
  }

  logOut() {
    authModule.actions.logout().then(() => {
      this.$router.push({ name: "login" });
    });
  }
}
