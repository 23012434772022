









































import { Component, Vue } from "vue-property-decorator";
import KTMenu from "./Menu.vue";
import KTTopbar from "./Topbar.vue";

import { layoutModule } from "@/store";

@Component({
  components: {
    KTMenu,
    KTTopbar,
  },
})
export default class KTHeader extends Vue {
  get headerClasses(): string[] {
    return layoutModule.state.mobileMenuClasses;
  }

  get mobileMenuIsOpen() {
    return this.headerClasses.includes("header-menu-wrapper-on");
  }

  get widthFluid() {
    return layoutModule.state.widthFluid;
  }

  closeMobileMenu(): void {
    layoutModule.mutations.closeMobileMenu();
  }
}
