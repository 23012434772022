var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthenticated)?_c('div',{staticClass:"d-flex flex-column flex-root",class:{
    'page-loading': _vm.loaderEnabled,
    'aside-enabled': false,
    'aside-secondary-enabled': false,
    'aside-fixed': false,
  }},[_c('KTHeaderMobile'),(_vm.loaderEnabled)?_c('Loader'):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper"},[_c('div',{staticClass:"content d-flex flex-column flex-column-fluid"},[_c('KTHeader'),_c('div',{staticClass:"d-flex flex-column-fluid mt-sm-5"},[_c('div',{staticClass:"p-0 py-1 px-sm-5",class:{
              'container-fluid': _vm.widthFluid,
              container: !_vm.widthFluid,
            }},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)])],1),_c('KTFooter')],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }